import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Banner, BannerProps, IconName, MessageType, useBannerLayout, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useCreateNewRunbookVersion } from 'main/services/queries/use-runbook-versions'
import {
  ActiveAccountModel,
  ActiveDashboardModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  CurrentUserModel,
  DashboardModel,
  RunbookViewModel
} from 'main/data-access'
import { useRouting } from 'main/services/routing'

// TODO: This should be a configuration
const SNIPPET_HELP_LINK = 'https://help.cutover.com/en/articles/6414594-create-snippets'

export const RunbookBanner = () => {
  const navigate = useNavigate()
  const routes = useRouting()

  const {
    is_template: isTemplate,
    template_type: templateType,
    template_status: templateStatus,
    linked_runbook_details: linkedRunbookDetails
  } = ActiveRunbookModel.useGet()

  const {
    stage: runbookVersionStage,
    approval_status: approvalStatus,
    reviews,
    reviews_required: reviewsRequired,
    id,
    runbook_id: runbookId,
    is_current: isCurrent
  } = ActiveRunbookVersionModel.useGet()
  const run = ActiveRunModel.useGet()
  const canManageTemplate = ActiveRunbookVersionModel.useCan('manage_template')
  const getDashboard = DashboardModel.useGetByCallback()
  const { slug: accountSlug } = ActiveAccountModel.useGet()
  const activeDashboard = ActiveDashboardModel.useGet()
  const processRunbookVersionCreateResponse = ActiveRunbookVersionModel.useOnAction('create')
  const canCreateRunbookVersion = ActiveRunbookVersionModel.useCan('create')
  const { can: showPIRLink } = RunbookViewModel.usePermission('show:pir-link')
  const canResumeRun = ActiveRunModel.useCan('resume')
  const currentUser = CurrentUserModel.useGet()
  const currentUserIsReviewer = reviews?.[0]?.reviewer?.id === currentUser?.id

  const { mutate: createVersion } = useCreateNewRunbookVersion(runbookId)

  const openModal = RunbookViewModel.useAction('modal:open')
  const { showBannerLayout, hideBannerLayout } = useBannerLayout()
  const { t } = useLanguage('runbook', { keyPrefix: 'banner' })
  const notify = useNotify()
  const runType = run?.run_type

  const linkedParentStatus = linkedRunbookDetails && linkedRunbookDetails.current_status
  const linkedParentName = linkedRunbookDetails?.linked_resource?.name
  const linkedParentId = linkedRunbookDetails?.linked_resource?.id

  const stageInheritedFromParent =
    linkedParentStatus?.actioned_by === 'task' && linkedParentStatus?.linked_resource !== 'active'
      ? linkedParentStatus.linked_resource
      : undefined

  const stage = stageInheritedFromParent ?? runbookVersionStage

  const handleSaveVersion = async () => {
    createVersion(
      { base_version_id: id },
      {
        onSuccess: response => {
          processRunbookVersionCreateResponse(response)
        },
        onError: () => notify.error(t('createVersionError'))
      }
    )
  }

  const banner: BannerProps | undefined = useMemo(():
    | {
        message: string
        type: MessageType
        icon: boolean
        iconName?: IconName
        linkText?: string
        linkTextSecondary?: string
        linkTextInline?: string
        onClick?: () => void
        onClickSecondary?: () => void
        onClickInlineLink?: () => void
      }
    | undefined => {
    if (templateType === 'snippet' && templateStatus === 'template_draft') {
      return {
        message: t('snippetApproval.message'),
        type: 'idea',
        icon: false,
        linkTextInline: t('snippetApproval.linkText'),
        onClickInlineLink: () => window.open(SNIPPET_HELP_LINK, '_blank')
      }
    }

    if (!isCurrent) {
      return {
        type: 'idea',
        message: t('message.oldVersion'),
        linkText: canCreateRunbookVersion
          ? isTemplate
            ? t('linkText.createNewTemplateVersion')
            : t('linkText.restoreVersion')
          : undefined,
        onClick: canCreateRunbookVersion ? handleSaveVersion : undefined,
        linkTextSecondary: t('linkText.goToCurrentVersion'),
        onClickSecondary: () => navigate(routes.toRunbook({ accountSlug, runbookId })),
        icon: false
      }
    }

    if (approvalStatus === 'approved') {
      return {
        message: t('templateApproval.approved.message'),
        type: 'success',
        linkText: canManageTemplate ? t('templateApproval.approved.linkText') : undefined,
        onClick: handleSaveVersion,
        icon: true
      }
    } else if (approvalStatus === 'pending') {
      if (reviewsRequired) {
        return {
          message: t('templateApproval.draftReviewRequired.message'),
          type: 'idea',
          linkText: canManageTemplate ? t('templateApproval.draftReviewRequired.linkText') : undefined,
          onClick: () => {
            openModal({ type: 'request-approval' })
          },
          icon: false
        }
      } else {
        return {
          message: t('templateApproval.draft.message'),
          type: 'idea',
          linkText: canManageTemplate ? t('templateApproval.draft.linkText') : undefined,
          onClick: () => {
            openModal({ type: 'mark-as-approved' })
          },
          icon: false
        }
      }
    } else if (approvalStatus === 'open') {
      if (currentUserIsReviewer) {
        return {
          message: t('templateApproval.awaitingReviewUserIsReviewer.message'),
          type: 'warning',
          linkText: t('templateApproval.awaitingReviewUserIsReviewer.linkText'),
          onClick: () => {
            openModal({ type: 'submit-review' })
          },
          icon: true,
          iconName: 'time'
        }
      } else {
        return {
          message: canManageTemplate
            ? t('templateApproval.awaitingReviewUserCanManage.message')
            : t('templateApproval.awaitingReview.message'),
          type: 'warning',
          linkText: canManageTemplate ? t('templateApproval.awaitingReviewUserCanManage.linkText') : undefined,
          onClick: () => {
            openModal({ type: 'cancel-approval' })
          },
          icon: true,
          iconName: 'time'
        }
      }
    } else if (approvalStatus === 'rejected') {
      if (currentUserIsReviewer) {
        return {
          message: t('templateApproval.rejectedWhenUserIsReviewer.message'),
          type: 'error',
          icon: true
        }
      } else {
        return {
          message: t('templateApproval.rejected.message', { reviewer: reviews?.[0].reviewer.name }),
          type: 'error',
          linkText: canManageTemplate ? t('templateApproval.rejected.linkText') : undefined,
          onClick: () => {
            openModal({ type: 'view-review' })
          },
          icon: true
        }
      }
    } else if (approvalStatus === 'expiring') {
      return {
        message: t('templateApproval.expiring.message'),
        type: 'warning',
        linkText: canManageTemplate ? t('templateApproval.expiring.linkText') : undefined,
        onClick: () => {
          openModal({ type: 'request-reapproval' })
        },
        icon: true
      }
    } else if (approvalStatus === 'expired') {
      return {
        message: t('templateApproval.expired.message'),
        type: 'error',
        linkText: canManageTemplate ? t('templateApproval.expired.linkText') : undefined,
        onClick: () => {
          openModal({ type: 'request-reapproval' })
        },
        icon: true
      }
    }

    switch (stage) {
      case 'complete':
        if (activeDashboard?.key === 'pir') return
        if (runType === 'live') {
          return {
            message: t('message.complete'),
            type: 'idea',
            icon: false,
            linkText: showPIRLink ? t('linkText.viewPir') : undefined,
            onClick: showPIRLink
              ? async () => {
                  const dashboard = await getDashboard({ key: 'pir' })
                  if (!dashboard) return

                  navigate(routes.toDashboard({ accountSlug, runbookId, versionId: id, dashboardId: dashboard.id }))
                }
              : undefined
          }
        }
        if (runType === 'rehearsal') {
          return {
            message: t('message.rehearsalComplete'),
            type: 'info',
            linkText: canCreateRunbookVersion ? t('linkText.rehearsalComplete') : undefined,
            onClick: canCreateRunbookVersion ? handleSaveVersion : undefined,
            icon: false
          }
        }
      case 'cancelled':
        if (runType !== 'live') return
        // display this message if it's not a linked runbook or the linked runbook was cancelled by itself
        if (!linkedRunbookDetails || linkedRunbookDetails.linked_resource?.stage !== 'cancelled') {
          return {
            message: t('message.cancelled'),
            type: 'error',
            icon: true
          }
        } else {
          return {
            message: t('message.childCancelled'),
            linkTextInline: `${linkedParentName}`,
            onClickInlineLink: () => {
              linkedParentId && navigate(routes.toRunbook({ accountSlug, runbookId: linkedParentId }))
            },
            type: 'error',
            icon: false
          }
        }
      case 'paused':
        return {
          message: t('message.paused'),
          type: 'info',
          linkText: !!canResumeRun ? t('linkText.paused') : undefined,
          onClick: () => {
            openModal({ type: 'run-resume' })
          },
          icon: false
        }
      case 'skipped':
        return {
          message: t('message.childSkipped'),
          linkTextInline: `${linkedParentName}`,
          onClickInlineLink: () => {
            linkedParentId && navigate(routes.toRunbook({ accountSlug, runbookId: linkedParentId }))
          },
          type: 'error',
          icon: false
        }
      case 'abandoned':
        return {
          message: t('message.childAbandoned'),
          linkTextInline: `${linkedParentName}`,
          type: 'error',
          onClickInlineLink: () => {
            linkedParentId && navigate(routes.toRunbook({ accountSlug, runbookId: linkedParentId }))
          },
          icon: false
        }
      case 'planning':
        // when child runbook is cancelled during parent rehearsal run
        if (linkedParentStatus?.linked_resource === 'cancelled') {
          return {
            message: t('message.cancelled'),
            type: 'error',
            icon: true
          }
        }
      default:
        undefined
    }
  }, [
    showPIRLink,
    stage,
    runType,
    approvalStatus,
    openModal,
    canCreateRunbookVersion,
    canManageTemplate,
    currentUserIsReviewer,
    handleSaveVersion,
    handleSaveVersion
  ])

  useEffect(() => {
    // Reduces height of InnerContent so there is space for banner below
    if (banner) {
      showBannerLayout()
    } else {
      hideBannerLayout()
    }
    return () => {
      hideBannerLayout()
    }
  }, [banner])

  return (
    <>
      {banner ? (
        <Banner
          message={banner.message}
          type={banner.type}
          icon={banner.icon}
          iconName={banner.iconName}
          linkText={banner.linkText}
          linkTextSecondary={banner.linkTextSecondary}
          linkTextInline={banner.linkTextInline}
          onClick={banner.onClick}
          onClickSecondary={banner.onClickSecondary}
          onClickInlineLink={banner.onClickInlineLink}
          children={banner.children}
        />
      ) : undefined}
    </>
  )
}
