import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import * as yup from 'yup'
import styled from 'styled-components'
import { usePrevious, useUpdateEffect } from 'react-use'
import { isEmpty } from 'lodash'

import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  DayTimePicker,
  DurationPicker,
  Icon,
  IconButton,
  Message,
  RIGHT_PANEL_SCROLL_CONTAINER_ID,
  SettableFieldType,
  TaskTypeIcon,
  Tooltip
} from '@cutover/react-ui'
import { TasksDeleteModal } from 'main/components/runbook/modals/task-modals/tasks-delete-modal'
import {
  CheckboxFieldControlled,
  DateTimePickerField,
  DurationPickerField,
  FormEditPanel,
  hasDynamicTemplate,
  RadioboxGroupField,
  SelectField,
  SnippetSelectField,
  StreamSelectField,
  TextAreaField,
  TextEditorField
} from 'main/components/shared/form'
import {
  TaskShowPermissions,
  useGetTaskShowPermissions,
  useTaskEditTaskTypes,
  useTaskEditUpdatedState,
  useTaskNotifications,
  useTaskTypeRemovedName
} from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { FieldValue, RunbookTemplateRunbook, TaskListTask, TaskShowTask, TaskType } from 'main/services/queries/types'
import { getTask, setTask, TaskEditRestrcitedTaskPayload, TaskEditTaskPayload } from 'main/services/queries/use-task'
import { RunbookTaskUpdateResponse } from 'main/services/api/data-providers/runbook-types'
import { TaskCommsSettingsModal } from 'main/components/runbook/modals/task-modals/task-comms-settings-modal'
import { customFieldValidation, useCustomFieldFormCallback } from 'main/components/shared/custom-field-form'
import { buildDefaultFieldValues } from 'main/components/shared/custom-field-form/custom-field-form-helper'
import {
  CustomFieldsGroupsForm,
  CustomFieldsGroupsFormProps
} from 'main/components/shared/custom-field-form/custom-field-groups-form'
import { TaskEditDependenciesPanel } from './dependencies-panel'
import { TaskActionContent } from './task-action-content'
import { TaskAdvancedSettings } from './task-advanced-settings'
import { TaskOtherDetails } from './task-other-details'
import { TaskWebhooks } from './task-webhooks'
import { FormType } from 'main/components/shared/form/form'
import { TaskCommsStatus } from './task-comms-status'
import { TaskEditAssignedUsersAndTeamsPanel } from './assigned-users-teams-panel'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { CompletedTaskButton, InProgressTaskButton, StartableTaskButton } from './task-action-buttons'
import { useSetActiveRightPanelTypeState } from 'main/components/layout/right-panel'
import { TaskEditDescriptionModal } from './task-edit-description-modal'
import {
  ActiveAccountModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  CommentModel,
  ConfigModel,
  CurrentRunbookVersionModel,
  CustomFieldGroupModel,
  CustomFieldModel,
  CustomFieldUserModel,
  RunbookViewModel,
  StreamModel,
  TaskModel,
  TaskTypeModel
} from 'main/data-access'
import { DynamicTextField } from 'main/components/shared/form/dynamic-text-field/dynamic-text-field'
import { TaskSourceRunbookPanel } from './task-source-runbook-panel'
import { TaskEditDeleteCfData } from './task-edit-delete-cf-data-content'
import { TaskEditUnlinkContent } from './task-edit-unlink-content'

type TaskEditPayload = TaskEditTaskPayload | TaskEditRestrcitedTaskPayload

export const TaskEditForm = ({ data: taskListTask, onClose }: { data: TaskListTask; onClose: () => void }) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel' })
  const { id: taskId, internal_id: taskInternalId, stage, comments_count: commentsCount } = taskListTask
  const descriptionFieldRef = useRef<SettableFieldType>(null)
  const taskComments = CommentModel.useGetAllBy({ taskInternalId })
  const commentsCountState = taskComments.length
  const run = ActiveRunModel.useGet()
  const taskEditFormRef = useRef<FormType<TaskEditFormType>>(null)
  const getTaskShowPermissions = useGetTaskShowPermissions()
  const { id: runbookId, is_template: isTemplate } = ActiveRunbookModel.useGet()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const { openRightPanel: openCommentsPanel } = useSetActiveRightPanelTypeState('runbook-comments')
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false)
  const [customFieldConstraints, setCustomFieldConstraints] = useState<Record<number, number[]>>({})
  const [taskAuthorName, setTaskAuthorName] = useState<string | undefined>(undefined)
  const [unlinkingResource, setUnlinkingResource] = useState(false)
  const [can, setPermissions] = useState<TaskShowPermissions | null>(null)
  const [taskTypeId, setTaskTypeId] = useState<number>(taskListTask.task_type_id)
  const processTaskUpdateResponse = TaskModel.useOnAction('update')
  const updateFunctionalOauthUser = TaskModel.useAction('update_functional_oauth_user')
  const skipTask = TaskModel.useAction('skip')
  const taskLookup = TaskModel.useGetLookup()
  const { notifyTaskAction } = useTaskNotifications()
  const customFieldsLookup = CustomFieldModel.useGetLookup()
  const customFieldGroups = CustomFieldGroupModel.useGetAll()
  const customFieldsGroupsLookup = CustomFieldGroupModel.useGetLookup()
  const customFieldUsers = CustomFieldUserModel.useGetAll()
  const currentTaskType = TaskTypeModel.useGet(taskTypeId)
  const [updatedTask, setUpdatedTask] = useTaskEditUpdatedState()
  const { dynamic: isDynamic } = ActiveRunbookModel.useRunbookType()
  const taskTypeLookup = TaskTypeModel.useGetLookup()
  const linkableIds = Object.entries(taskTypeLookup)
    .filter(([, rbType]) => rbType.linkable)
    .map(([id]) => parseInt(id))

  const { getFieldValues, fieldValueValidation, buildFieldValuesAttributesRequestData, getCustomFieldData } =
    useCustomFieldFormCallback({
      applyToSlugs: ['task_edit'],
      customFieldsLookup,
      customFieldGroups
    })

  const { customFields, groupedCustomFields, integrationGroupedCustomFields } = getCustomFieldData(
    currentTaskType.integration_action_items?.[0],
    { task_type_id: taskTypeId }
  )

  // TODO: Address completion type in the context of linked resources. Angular computes a
  // frontend attribute for it. See angular's task_active_model _getTaskCompletionStatus function
  useEffect(() => {
    if (!taskEditFormRef.current) return

    const { isDirty } = taskEditFormRef.current.formState

    if (!isDirty || (updatedTask && updatedTask.id === taskListTask.id)) {
      // If form is not dirty or same task was edited, reset the whole form
      if (updatedTask) {
        taskEditFormRef.current.reset(buildDefaultValues(updatedTask))
      } else {
        taskEditFormRef.current.reset(buildDefaultValues(taskListTask))
      }
    } else {
      if (updatedTask) {
        taskEditFormRef.current.reset(buildDefaultValues(updatedTask))
      } else {
        // If form is dirty, reset only certain fields. See task_active_model syncFromList method.
        const resetField = taskEditFormRef.current.resetField
        const possiblePredecessorsIds = taskEditFormRef.current?.getValues('possible_predecessors_ids')

        resetField('name', { defaultValue: taskListTask.name })
        resetField('description', { defaultValue: taskListTask.description })
        resetField('start_display', { defaultValue: taskListTask.start_display })
        resetField('end_display', { defaultValue: taskListTask.end_display })
        resetField('stage', { defaultValue: taskListTask.stage })
        resetField('predecessor_ids', { defaultValue: taskListTask.predecessor_ids })
        resetField('possible_predecessors_ids', {
          defaultValue: taskListTask.possible_predecessors_ids ?? possiblePredecessorsIds
        })
        resetField('successor_ids', { defaultValue: taskListTask.successor_ids })
      }
    }
    setUpdatedTask(undefined)
  }, [taskListTask])

  // TODO: Address completion type in the context of linked resources. Angular computes a
  // frontend attribute for it. See angular's task_active_model _getTaskCompletionStatus function
  const getDefaultValues = async () => {
    const response = await getTask({ runbookId, runbookVersionId, taskId })
    setPermissions(getTaskShowPermissions(response))
    const task = response?.task ?? {}
    updateFunctionalOauthUser(task)
    setTaskAuthorName(task.author_name)

    return buildDefaultValues({ ...task, ...taskListTask })
  }

  const buildDefaultValues = (task: TaskShowTask | TaskListTask) => {
    const fieldValues = getFieldValues(taskListTask.field_values)
    const currentNameTemplate = taskEditFormRef.current?.getValues('name_template')
    const possiblePredecessorsIds = taskEditFormRef.current?.getValues('possible_predecessors_ids')

    return {
      ...task,
      auto_finish: !!task.auto_finish,
      disable_notify: task.disable_notify.toString(),
      description: task.description,
      name_template: task.name_template
        ? task.name_template
        : hasDynamicTemplate(currentNameTemplate ?? '')
        ? currentNameTemplate
        : undefined,
      start_fixed: task.start_fixed ? new Date(task.start_fixed * 1000) : null,
      end_fixed: task.end_fixed ? new Date(task.end_fixed * 1000) : null,
      end_display: taskListTask.end_display * 1000,
      start_display: taskListTask.start_display * 1000,
      possible_predecessors_ids: task.possible_predecessors_ids ?? possiblePredecessorsIds,
      functional_oauth_user: task.functional_oauth_user,
      runbook_component_field_values: (task as TaskShowTask).runbook_component_field_values,
      field_values: buildDefaultFieldValues(
        customFields,
        groupedCustomFields,
        fieldValues,
        integrationGroupedCustomFields
      ),
      linked_resource_id: task.linked_resource?.id,
      linked_resource_name: task.linked_resource?.meta?.name
    }
  }

  const handleSuccess = useCallback(
    (response: RunbookTaskUpdateResponse) => {
      notifyTaskAction(response)

      const currentFormValues = taskEditFormRef.current?.getValues()
      const fieldValues: { [x: number]: FieldValue } = {}
      response.task.field_values.forEach(fv => (fieldValues[fv.custom_field_id] = fv))
      taskEditFormRef.current?.reset({
        ...currentFormValues,
        field_values: fieldValues,
        recipient_runbook_team_ids: response.task.recipient_runbook_team_ids,
        recipient_user_ids: response.task.recipient_user_ids,
        recipients: undefined,
        linked_resource_id: response.task.linked_resource?.id
      })
      processTaskUpdateResponse(response)
    },
    [processTaskUpdateResponse, notifyTaskAction]
  )

  const handleError = (e: any) => {
    if (e instanceof ApiError) {
      document.getElementById(RIGHT_PANEL_SCROLL_CONTAINER_ID)?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  const handleClickComments = () => {
    openCommentsPanel({ taskId, taskInternalId })
  }

  useUpdateEffect(() => {
    const fieldValues = taskEditFormRef.current?.getValues('field_values') || {}
    const updatedDefaultFieldValues = buildDefaultFieldValues(
      customFields,
      groupedCustomFields,
      fieldValues,
      integrationGroupedCustomFields
    )

    setCustomFieldConstraints(calculateCfConstraints)

    setUnlinkingResource(calcualateLinkedResource)

    taskEditFormRef.current?.setValue('field_values', updatedDefaultFieldValues)
  }, [taskTypeId])

  const calcualateLinkedResource = useMemo(() => {
    const originalTypeLinkable = taskTypeLookup[taskListTask.task_type_id]?.linkable
    const newTypeLinkable = taskTypeLookup[taskTypeId]?.linkable

    return originalTypeLinkable && !newTypeLinkable
  }, [taskTypeLookup, taskTypeId])

  const calculateCfConstraints = useMemo(() => {
    const taskSnapshot = taskLookup[taskId]
    const customFieldIds = taskSnapshot.field_values.map(fv => fv.custom_field_id)

    let cfConstraints: Record<number, number[]> = {}
    for (const id of customFieldIds) {
      if (
        customFieldsLookup[id].constraint &&
        !customFieldsLookup[id].constraint?.task_type_id?.includes(taskEditFormRef.current?.getValues('task_type_id'))
      ) {
        if (!cfConstraints[taskId]) {
          cfConstraints[taskId] = []
        }
        cfConstraints[taskId].push(id)
      }
    }

    return cfConstraints
  }, [taskTypeId])

  const handleSubmit = useCallback(
    async (values: TaskEditPayload) => {
      return setTask({ runbookId, runbookVersionId, taskId, payload: values })
    },
    [runbookId, runbookVersionId, taskId]
  )

  const footerButton = useMemo(() => {
    switch (stage) {
      case 'startable':
        return <StartableTaskButton taskId={taskListTask.id} />
      case 'in-progress':
        return <InProgressTaskButton taskId={taskListTask.id} />
      case 'complete':
        return <CompletedTaskButton taskId={taskListTask.id} />
      default:
        return null
    }
  }, [stage])

  const handleSkipTask = () => skipTask(taskId)

  const canEditRestrictedAttributesOnly =
    (!isDynamic && run?.mode === 'active') || (isDynamic && !['default', 'startable'].includes(stage))

  const dataTransformer = ({
    field_values,
    ...rest
  }: {
    field_values: Record<number, FieldValue>
  } & TaskEditFormType) => {
    if (canEditRestrictedAttributesOnly) {
      const data = rest
      if (isDynamic) {
        return {
          task: {
            name: data.name,
            description: data.description,
            message: data.message,
            disable_notify: data.disable_notify === 'true',
            duration: data.duration,
            end_requirements: data.end_requirements
          },
          linked_snippets: data.linked_snippets,
          recipients: data.recipients,
          runbook_teams: data.runbook_team_ids,
          users: data.user_ids
        } as TaskEditRestrcitedTaskPayload
      } else {
        return {
          task: {
            name: data.name,
            description: data.description,
            message: data.message,
            disable_notify: data.disable_notify === 'true'
          },
          recipients: data.recipients
        } as TaskEditRestrcitedTaskPayload
      }
    } else {
      const data = rest
      const base = {
        task: {
          id: taskId,
          auto_finish: data.auto_finish,
          description: data.description === '<p></p>' ? null : data.description,
          disable_notify: data.disable_notify === 'true',
          duration: data.duration,
          end_fixed: data.end_fixed,
          end_requirements: data.end_requirements,
          field_values_attributes: buildFieldValuesAttributesRequestData(field_values),
          level: data.level,
          message: data.message,
          name: data.name_template ?? data.name,
          start_fixed: data.start_fixed,
          start_requirements: data.start_requirements,
          stream_id: data.stream_id,
          task_type_id: data.task_type_id
        },
        linked_snippets: data.linked_snippets,
        recipients: data.recipients,
        predecessors: data.predecessor_ids,
        runbook_teams: data.runbook_team_ids,
        users: data.user_ids
      } as TaskEditTaskPayload

      if (data.linked_resource_id) {
        base.linked_resource = {
          id: data.linked_resource_id,
          type: 'Runbook'
        }
      }

      return base
    }
  }

  const readOnly = !can?.update || ['in-progress', 'complete'].includes(taskListTask.stage)

  return (
    <>
      <TasksDeleteModal taskIds={[taskId]} open={showDeleteTaskModal} onClose={() => setShowDeleteTaskModal(false)} />
      <FormEditPanel<TaskEditFormType, TaskEditPayload>
        key={taskId}
        ref={taskEditFormRef}
        onReset={() => descriptionFieldRef.current?.reset()}
        title={t('title', { internalId: taskInternalId })}
        defaultValues={getDefaultValues}
        onSubmit={handleSubmit}
        onClose={onClose}
        schema={buildTaskEditFormSchema(fieldValueValidation, linkableIds)}
        readOnly={readOnly}
        onSuccess={handleSuccess}
        onError={handleError}
        transformer={dataTransformer}
        headerItems={[
          ...(can?.skip
            ? [<IconButton tipPlacement="top" label={t('iconSkipLabel')} icon="skip" onClick={handleSkipTask} />]
            : []),
          <IconButton
            icon="message"
            label="Comments"
            onClick={handleClickComments}
            disableTooltip
            {...(commentsCountState || commentsCount
              ? { badge: { label: commentsCountState || commentsCount, type: 'primary' } }
              : {})}
          />,
          ...(can?.destroy
            ? [
                <IconButton
                  tipPlacement="top"
                  label={t('iconDeleteLabel')}
                  icon="trash-o"
                  onClick={() => setShowDeleteTaskModal(true)}
                />
              ]
            : [])
        ]}
        hasConfirmModal={!!unlinkingResource || !isEmpty(customFieldConstraints)}
        confirmModalTitle={
          !!unlinkingResource ? t('fields.linkedResource.unlinkTitle') : t('customFieldDeleteData.title')
        }
        confirmModalDescription={
          !!unlinkingResource ? (
            <TaskEditUnlinkContent
              resourceType={isTemplate ? 'template' : 'runbook'}
              linkedTasks={[taskListTask]}
              displayType="linked_resource"
            />
          ) : (
            <TaskEditDeleteCfData constraints={customFieldConstraints} />
          )
        }
        confirmModalButtonLabel={
          !!unlinkingResource ? t('fields.linkedResource.unlinkButton') : t('customFieldDeleteData.buttonLabel')
        }
        onCloseConfirmModal={() => {
          setUnlinkingResource(calcualateLinkedResource)
          setCustomFieldConstraints(calculateCfConstraints)
        }}
        onContinueConfirmModal={() => {
          if (!isEmpty(customFieldConstraints) && !unlinkingResource) {
            setCustomFieldConstraints({})
          }
          setUnlinkingResource(false)
        }}
        canContinueConfirmModal={() =>
          (!unlinkingResource && !isEmpty(customFieldConstraints)) ||
          (!!unlinkingResource && isEmpty(customFieldConstraints))
        }
        footer={!!run ? footerButton : undefined}
      >
        {can && (
          <TaskEditFormFields
            formRef={taskEditFormRef}
            task={taskListTask}
            permissions={can}
            readOnly={readOnly}
            descriptionFieldRef={descriptionFieldRef}
            customFields={customFields}
            customFieldGroupsLookup={customFieldsGroupsLookup}
            groupedCustomFields={groupedCustomFields}
            customFieldUsers={customFieldUsers}
            integrationGroupedCustomFields={integrationGroupedCustomFields}
            setTaskTypeId={setTaskTypeId}
            taskAuthorName={taskAuthorName}
          />
        )}
      </FormEditPanel>
    </>
  )
}

const buildTaskEditFormSchema = (
  fieldValueValidation: ReturnType<typeof customFieldValidation>,
  linkableIds: number[]
) => {
  return yup.object({
    auto_finish: yup.boolean().nullable(),
    disable_notify: yup.string().oneOf(['true', 'false']),
    description: yup.string().nullable(),
    duration: yup.number().nullable(),
    end_actual: yup.number().nullable(),
    end_display: yup.number().nullable(),
    end_fixed: yup.mixed().nullable(), // is received as a number but it's saved as a Date object
    end_planned: yup.number().nullable(),
    end_requirements: yup.string().oneOf(['any_can_end', 'all_must_end', 'same_must_end']),
    field_values: fieldValueValidation,
    level: yup.string().oneOf(['level_1', 'level_2', 'level_3']),
    linked_resource_id: yup.number().when('task_type_id', {
      is: (taskTypeId: number) => linkableIds.indexOf(taskTypeId) !== -1,
      then: schema => schema.required(),
      otherwise: schema => schema.nullable()
    }),
    linked_resource_name: yup.string().when('task_type_id', {
      is: (taskTypeId: number) => linkableIds.indexOf(taskTypeId) !== -1,
      then: schema => schema.required(),
      otherwise: schema => schema.nullable()
    }),
    message: yup.string().nullable(),
    name: yup.string().required(),
    name_template: yup.string().nullable(),
    possible_predecessors_ids: yup.array().of(yup.number().required()).notRequired(),
    predecessor_ids: yup.array().of(yup.number().required()).notRequired(),
    recipients: yup
      .object()
      .shape({
        users: yup.array().of(yup.mixed()).notRequired(),
        runbook_teams: yup.array().of(yup.number().required()).notRequired()
      })
      .notRequired(),
    recipient_runbook_team_ids: yup.array().of(yup.number().required()).notRequired(),
    recipient_user_ids: yup.array().of(yup.number().required()).notRequired(),
    runbook_team_ids: yup.array().of(yup.number().required()).notRequired(),
    stage: yup.string().oneOf(['default', 'startable', 'in-progress', 'complete']),
    start_actual: yup.number().nullable(),
    start_display: yup.number().nullable(),
    start_fixed: yup.mixed().nullable(), // is received as a number but it's saved as a Date object
    start_latest_planned: yup.number().nullable(),
    start_requirements: yup.string().oneOf(['any_can_start', 'all_must_start']),
    stream_id: yup.number().required(),
    successor_ids: yup.array().of(yup.number().required()).notRequired(),
    task_type_id: yup.number().required(),
    linked_snippets: yup.array().of(yup.object()).notRequired(),
    user_ids: yup.array().of(yup.number().required()).notRequired(),
    functional_oauth_user: yup.boolean().nullable()
  })
}

export type TaskEditFormType = yup.InferType<ReturnType<typeof buildTaskEditFormSchema>>

const TaskEditFormFields = ({
  task,
  permissions: can,
  readOnly,
  setTaskTypeId,
  taskAuthorName,
  descriptionFieldRef,
  formRef,
  ...customFieldsProps
}: CustomFieldsGroupsFormProps & {
  task: TaskListTask
  permissions: TaskShowPermissions
  readOnly: boolean
  setTaskTypeId: (id: number) => void
  taskAuthorName?: string
  descriptionFieldRef: RefObject<SettableFieldType>
  formRef: RefObject<FormType<any>>
}) => {
  const {
    watch,
    setValue,
    getValues,
    formState: { errors, defaultValues, isSubmitting },
    getFieldState,
    control
  } = useFormContext<TaskEditFormType>()
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel' })
  const [showTaskCommsSettingsModal, setShowTaskCommsSettingsModal] = useState(false)
  const [isDescriptionModalOpen, setDescriptionModalOpen] = useState(false)
  const {
    stage: runbookVersionStage,
    start_scheduled: startScheduled,
    start_display: versionStartDisplay,
    timing_mode
  } = CurrentRunbookVersionModel.useGet()
  const run = ActiveRunModel.useGet()
  const { id: runbookId, timezone, is_template, settings_task_description_on_task_start } = ActiveRunbookModel.useGet()
  const taskTypeLookup = TaskTypeModel.useGetLookup()
  const streamLookup = StreamModel.useGetLookup()
  const { dynamic: isDynamic } = ActiveRunbookModel.useRunbookType()

  // Linked modal requirements
  const openModal = RunbookViewModel.useAction('modal:open')
  const runbookVersionId = ActiveRunbookVersionModel.useId()

  const taskTypeId = watch('task_type_id')
  const recipients = watch('recipients')
  const { taskTypes, taskTypeIntegrations } = useTaskEditTaskTypes()
  const taskTypeRemovedName = useTaskTypeRemovedName(task.task_type_id)
  const currentTaskType = TaskTypeModel.useGet(taskTypeId)
  const previousTaskType = usePrevious(currentTaskType)
  const taskTypesOptions =
    taskTypes?.map((t: TaskType) => ({ value: t.id, label: t.name, icon: <TaskTypeIcon icon={t.icon} /> })) ?? []
  const taskTypesIntegrationsOptions =
    taskTypeIntegrations?.map((t: TaskType) => ({
      value: t.id,
      label: t.integration_action_items[0].name,
      icon: (
        <IntegrationIcon
          alt={`${t.integration_action_items[0].name}-icon`}
          src={t.integration_action_items[0].image_url || t.integration_action_items[0].integration_setting.image_url}
        />
      )
    })) ?? []
  const { taskLevels, integrations } = ConfigModel.useGet()
  const integrationActionItem = currentTaskType.integration_action_items?.[0]
  const { slug: accountSlug } = ActiveAccountModel.useGet()
  const customFieldsLookup = CustomFieldModel.useGetLookup()

  const autoFinish = watch('auto_finish')
  const description = watch('description')
  const endDisplay = watch('end_display')
  const duration = watch('duration')
  const endFixed = watch('end_fixed')
  const endActual = watch('end_actual')
  const endPlanned = watch('end_planned')
  const endRequirements = watch('end_requirements')
  const message = watch('message')
  const possiblePredecessorsIds = watch('possible_predecessors_ids')
  const recipientUserIds = watch('recipient_user_ids')
  const recipientRunbookTeamIds = watch('recipient_runbook_team_ids')
  const runbookTeamIds = watch('runbook_team_ids')
  const startLatestPlanned = watch('start_latest_planned')
  const startActual = watch('start_actual')
  const stage = watch('stage')
  const startDisplay = watch('start_display')
  const startFixed = watch('start_fixed')
  const streamId = watch('stream_id')
  const successorIds = watch('successor_ids')
  const userIds = watch('user_ids')
  const functionalOauthUser = watch('functional_oauth_user')

  const isTaskTypeAutoFinish = currentTaskType.auto_finish
  const isTaskTypeBranching = currentTaskType.conditional_progression
  const isTaskTypeEnableStartFixed = currentTaskType.enable_start_fixed
  const isTaskTypeEnableEndFixed = currentTaskType.enable_end_fixed
  const isTaskTypeCommsOff = currentTaskType.comms === 'comms_off'
  const isTaskTypeIntegration = !!taskTypeIntegrations.find(taskType => taskType.id === currentTaskType.id)
  const isTaskTypeLinkable = currentTaskType.linkable
  const isTaskTypeSnippet = currentTaskType.key === 'snippet'
  const isTaskTypeValidation = currentTaskType.key === 'validation'

  const { getCustomFieldData: getTaskEndCustomFieldData } = useCustomFieldFormCallback({
    applyToSlugs: ['task_end'],
    customFieldsLookup
  })

  const { customFields: taskEndCustomFields } = getTaskEndCustomFieldData(
    currentTaskType.integration_action_items?.[0],
    {
      task_type_id: taskTypeId
    }
  )

  const hasRequiredTaskEndCustomField = !!taskEndCustomFields?.find(customField => customField.required)

  const isRunbookPlanningOrPaused = !run || run?.mode === 'paused'
  const durationDifference = useMemo(
    () =>
      endDisplay && startDisplay
        ? {
            start: (endDisplay - startDisplay) / 1000,
            end: duration ?? 0
          }
        : undefined,
    []
  )

  const endDifference = useMemo(
    () =>
      endDisplay && endPlanned && !(is_template || timing_mode === 'unscheduled')
        ? {
            start: endDisplay / 1000,
            end: endFixed ? Math.max(endPlanned, (endFixed as number) / 1000) : endPlanned
          }
        : undefined,
    []
  )
  const startDifference = useMemo(
    () =>
      startDisplay && startLatestPlanned && !(is_template || timing_mode === 'unscheduled')
        ? {
            start: startDisplay / 1000,
            end: startLatestPlanned
          }
        : undefined,
    []
  )

  const permittedStreams = StreamModel.useGetAll({ scope: 'permitted' })
  const selectedStream = StreamModel.useGet(streamId)
  const selectedStreamColor = selectedStream?.color
  const streams = !readOnly ? permittedStreams : [selectedStream]

  const shouldShowTimingField = (arg: any) =>
    (startScheduled || run) && (!(is_template || timing_mode === 'unscheduled') || !!arg)

  const mergedTaskListAndShow = useMemo(() => ({ ...defaultValues, ...task }), [task, defaultValues])

  const recipientCount = recipients
    ? (recipients.users?.length ?? 0) + (recipients.runbook_teams?.length ?? 0)
    : (recipientUserIds?.length ?? 0) + (recipientRunbookTeamIds?.length ?? 0)

  const allowInProgressEdit = (() => {
    if (!run || run.mode === 'cancelled') {
      return can?.update
    }

    if ((isDynamic && run.mode === 'active') || run.mode === 'paused') {
      return task.end_actual ? can?.update : can?.in_progress_update
    }

    return can?.update
  })()

  useEffect(() => {
    if (startDisplay && duration && getFieldState('duration').isDirty && isRunbookPlanningOrPaused) {
      setValue('end_display', startDisplay + duration * 1000, { shouldDirty: false, shouldTouch: false })
    }
  }, [duration])

  useEffect(() => {
    const userHasUpdatedTaskType = previousTaskType && currentTaskType.id !== previousTaskType?.id
    if (!userHasUpdatedTaskType) return

    if (previousTaskType.enable_end_fixed && currentTaskType.enable_end_fixed && endFixed) {
      setValue('end_fixed', new Date(endFixed as number))
    }

    if (previousTaskType.enable_end_fixed && !currentTaskType.enable_end_fixed && endFixed) {
      setValue('end_fixed', null)
    }

    if (previousTaskType.enable_start_fixed && !currentTaskType.enable_start_fixed && startFixed) {
      setValue('start_fixed', null)
    }

    setTaskTypeId(taskTypeId)
  }, [previousTaskType, currentTaskType, endFixed, startFixed])

  useEffect(() => {
    if (!isTaskTypeSnippet) {
      setValue('linked_snippets', [])
    }
  }, [isTaskTypeSnippet, setValue])

  useEffect(() => {
    if (
      isTaskTypeAutoFinish ||
      isTaskTypeLinkable ||
      isTaskTypeBranching ||
      isTaskTypeIntegration ||
      hasRequiredTaskEndCustomField ||
      isTaskTypeValidation ||
      endRequirements === 'all_must_end' ||
      endRequirements === 'same_must_end'
    ) {
      setValue('auto_finish', false)
    }
  }, [
    isTaskTypeAutoFinish,
    isTaskTypeLinkable,
    isTaskTypeBranching,
    isTaskTypeIntegration,
    hasRequiredTaskEndCustomField,
    isTaskTypeValidation,
    endRequirements
  ])

  const integrationDescription = useMemo(() => {
    if (!integrationActionItem) {
      return
    }

    const integration = integrations.find(i => i.name === integrationActionItem?.integration_setting?.integration_title)
    const integrationAction = integration?.actions?.find(
      action => action.klass === integrationActionItem?.integration_action
    )
    return integrationAction?.description
  }, [integrationActionItem, integrations])

  const handleDescriptionModalConfirm = (descriptionModalValue: string) => {
    descriptionFieldRef.current?.setValue?.(descriptionModalValue)
    setDescriptionModalOpen(false)
  }

  const templateValue = getValues('name_template')
  const hasDynamicTitle = templateValue !== undefined && templateValue !== null

  return (
    <>
      {isDescriptionModalOpen && (
        <TaskEditDescriptionModal
          readOnly={readOnly}
          initialValue={description}
          onClose={() => setDescriptionModalOpen(false)}
          onClickConfirm={handleDescriptionModalConfirm}
        />
      )}
      {hasDynamicTitle ? (
        <DynamicTextField
          as="textarea"
          hasError={!!errors['name']}
          label={t('fields.title.label')}
          readOnly={readOnly}
          required
          templateKey="name_template"
          valueKey="name"
        />
      ) : (
        <TextAreaField<TaskEditFormType>
          data-testid="edit-task-title"
          name="name"
          label={t('fields.title.label')}
          readOnly={!allowInProgressEdit}
        />
      )}
      <Box flex direction="row" gap="small">
        <TaskTypeSelectWrapper isTaskTypeCommsOff={isTaskTypeCommsOff}>
          <SelectField<TaskEditFormType>
            name="task_type_id"
            options={[
              { header: t('fields.taskType.taskTypeSelectHeader') },
              ...taskTypesOptions,
              ...[
                ...(taskTypesIntegrationsOptions.length
                  ? [{ header: t('fields.taskType.integrationTaskTypeSelectHeader') }]
                  : [])
              ],
              ...taskTypesIntegrationsOptions
            ]}
            label={t('fields.taskType.label')}
            placeholder={!!taskTypeRemovedName ? taskTypeRemovedName : t('fields.taskType.placeholder')}
            noBottomPadding={!isTaskTypeCommsOff}
          />
        </TaskTypeSelectWrapper>

        {!isTaskTypeCommsOff && (
          <Button
            css="flex: 1; position: relative; top: 6px;"
            label={t('fields.commsSettings.settings')}
            secondary
            onClick={() => setShowTaskCommsSettingsModal(true)}
          />
        )}
      </Box>

      {isTaskTypeLinkable && (
        <Box flex direction="row" gap="small">
          <TextAreaField<TaskEditFormType>
            role="textbox"
            name="linked_resource_name"
            label={t('fields.linkedResource.label')}
            readOnly
            required
            disabled={isSubmitting}
          />
          {!readOnly && (
            <IconButton
              role="button"
              label={t('fields.linkedResource.editLinkedTempalate')}
              icon="edit"
              color="rgba(22, 22, 29, 0.37)"
              css="position: relative; top: 10px;"
              onClick={() => {
                openModal({
                  type: 'linked-template-selector',
                  data: {
                    accountId: accountSlug,
                    action: 'update',
                    multiselect: false,
                    runbookId: runbookId.toString(),
                    runbookVersionId: runbookVersionId.toString(),
                    onSubmit: (template: RunbookTemplateRunbook) => {
                      setValue('linked_resource_id', template.id, { shouldDirty: true })
                      setValue('linked_resource_name', template.name)
                    }
                  }
                })
              }}
            />
          )}
        </Box>
      )}
      {isTaskTypeSnippet && (
        <SnippetSelectField<TaskEditFormType>
          name="linked_snippets"
          label={t('fields.snippets.label')}
          a11yTitle={t('fields.snippets.addSnippet')}
          isSubmitting={isSubmitting}
          accountSlug={accountSlug}
        />
      )}
      {!isTaskTypeCommsOff && (
        <MessageStatusWrapper>
          <TaskCommsStatus
            condition={!!message}
            statusMessage={t('fields.commsSettings.messageStatus', { count: message ? 1 : 0 })}
            position="left"
            type="message"
          />
          <TaskCommsStatus
            condition={recipientCount > 0}
            statusMessage={t('fields.commsSettings.recipientsStatus', { count: recipientCount })}
            type="recipients"
          />
        </MessageStatusWrapper>
      )}
      <TaskCommsSettingsModal
        loadInitialRecipients={!recipients}
        open={showTaskCommsSettingsModal}
        recipientUserIds={recipientUserIds ?? []}
        recipientRunbookTeamIds={recipientRunbookTeamIds ?? []}
        onClose={() => setShowTaskCommsSettingsModal(false)}
        canModifyComms={can?.modify_comms}
        taskId={task.id}
        commsType={currentTaskType.comms}
      />
      <StreamSelectField<TaskEditFormType>
        label={t('fields.stream.label')}
        streams={streams ?? []}
        name="stream_id"
        clearable={false}
        placeholder={t('fields.stream.placeholder')}
        loading={streams === undefined}
      />
      <RadioboxGroupField<TaskEditFormType>
        name="level"
        label={t('fields.level.label')}
        direction="row"
        options={taskLevels.map(level => ({ value: level.id, label: level.name })).reverse()}
      />
      {shouldShowTimingField(startActual) && (
        <DateTimePickerField<TaskEditFormType>
          diff={startDifference}
          name="start_display"
          readOnly // always true as it's a computed value
          onChange={date => date}
          label={t('fields.forecastStart.label', { context: startActual ? 'started' : undefined })}
          timezone={timezone}
        />
      )}
      {!isTaskTypeAutoFinish && stage !== 'complete' && (
        <DurationPickerField<TaskEditFormType>
          label={t('fields.duration.label')}
          readOnly={!can?.update && !(isDynamic && task.stage === 'in-progress')}
          name="duration"
          diff={durationDifference}
        />
      )}
      {!isTaskTypeAutoFinish && stage === 'complete' && endDisplay && startDisplay && (
        // Not using DurationPickerField because value is computed
        <DurationPicker
          value={(endDisplay - startDisplay) / 1000}
          readOnly
          onChange={value => value}
          label={t('fields.duration.labelActual')}
          diff={durationDifference}
        />
      )}
      {shouldShowTimingField(endActual) && !isTaskTypeAutoFinish && (
        <DateTimePickerField<TaskEditFormType>
          diff={endDifference}
          name="end_display"
          readOnly // always true as it's a computed value
          onChange={date => date}
          label={t('fields.forecastFinish.label', { context: endActual ? 'finished' : undefined })}
          timezone={timezone}
        />
      )}
      {isTaskTypeEnableStartFixed && (
        <>
          {(isRunbookPlanningOrPaused || isDynamic) && (
            // Not using a checkbox field since it is setting the value of start_fixed. This checkbox is not registered with the form.
            <Checkbox
              label={t('fields.startFixed.checkboxLabel')}
              checked={startFixed !== null}
              disabled={isSubmitting}
              readOnly={readOnly}
              helpText={t('fields.startFixed.tooltip')}
              onChange={function (e) {
                e.preventDefault()
                setValue('start_fixed', startFixed === null ? new Date(startDisplay as number | Date) : null, {
                  shouldDirty: true,
                  shouldTouch: true
                })
              }}
            />
          )}
          {startFixed !== null && (
            <Box margin={{ top: '10px' }}>
              {timing_mode === 'unscheduled' && (
                <Controller
                  name="start_fixed"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DayTimePicker
                        dayZeroStartValue={versionStartDisplay ? new Date(versionStartDisplay * 1000) : new Date()}
                        onChange={date => (!!date ? onChange(date.toISOString()) : onChange(null))}
                        value={!!value ? new Date(value as number) : new Date()}
                        disabled={isSubmitting}
                        readOnly={readOnly}
                        timezone={timezone}
                      />
                    )
                  }}
                />
              )}
              {timing_mode === 'scheduled' && (
                <DateTimePickerField<TaskEditFormType>
                  name="start_fixed"
                  label={t('fields.startFixed.timeFieldLabel')}
                  disabled={isSubmitting}
                  readOnly={readOnly}
                  timezone={timezone}
                />
              )}
            </Box>
          )}
        </>
      )}
      {isTaskTypeEnableEndFixed && (
        <>
          {(isRunbookPlanningOrPaused || isDynamic) && (
            // Not using a checkbox field since it is setting the value of end_fixed. This checkbox is not registered with the form.
            <Checkbox
              label={t('fields.dueDate.checkboxLabel')}
              checked={endFixed !== null}
              disabled={isSubmitting}
              readOnly={readOnly}
              helpText={t('fields.dueDate.tooltip')}
              onChange={function (e) {
                e.preventDefault()
                setValue('end_fixed', endFixed === null ? new Date(endDisplay as number | Date) : null, {
                  shouldDirty: true,
                  shouldTouch: true
                })
              }}
            />
          )}
          {endFixed !== null && (
            <Box margin={{ top: '10px' }}>
              {timing_mode === 'unscheduled' && (
                // TODO: Add "day" time picker to smart form fields.
                <Controller
                  name="end_fixed"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DayTimePicker
                        dayZeroStartValue={versionStartDisplay ? new Date(versionStartDisplay * 1000) : new Date()}
                        onChange={date => (!!date ? onChange(date.toISOString()) : onChange(null))}
                        value={!!value ? new Date(value as number) : new Date()}
                        timezone={timezone}
                        disabled={isSubmitting}
                        readOnly={readOnly}
                      />
                    )
                  }}
                />
              )}
              {timing_mode === 'scheduled' && (
                <DateTimePickerField<TaskEditFormType>
                  name="end_fixed"
                  label={t('fields.dueDate.timeFieldLabel')}
                  timezone={timezone}
                  disabled={isSubmitting}
                  readOnly={readOnly}
                />
              )}
            </Box>
          )}
        </>
      )}

      {!isTaskTypeAutoFinish &&
        !isTaskTypeLinkable &&
        !isTaskTypeBranching &&
        !isTaskTypeIntegration &&
        !hasRequiredTaskEndCustomField &&
        !isTaskTypeValidation &&
        endRequirements !== 'all_must_end' &&
        endRequirements !== 'same_must_end' && (
          <>
            <Box margin={{ bottom: '16px' }}>
              <CheckboxFieldControlled<TaskEditFormType>
                name="auto_finish"
                label={t('fields.autoFinish.label')}
                helpText={t('fields.autoFinish.helpText')}
              />
            </Box>

            {autoFinish && (
              <Message margin data-testid="show-auto-finish-info" type="info">
                {t('fields.autoFinish.info')}
              </Message>
            )}
          </>
        )}
      <Accordion a11yTitle={t('fieldGroupsAccordion')} scrollContainer={RIGHT_PANEL_SCROLL_CONTAINER_ID}>
        <CustomFieldsGroupsForm
          {...customFieldsProps}
          formRef={formRef}
          integrationActionItem={integrationActionItem}
          iconColor={selectedStreamColor}
          errors={errors}
          readOnly={readOnly}
          integrationDescription={integrationDescription}
          functionalOauthUser={functionalOauthUser ?? false}
          taskId={task.id}
        />
        {runbookVersionStage !== 'planning' && (
          <AccordionPanel iconColor={selectedStreamColor} icon="play" label={t('actionAccordion.label')}>
            <TaskActionContent task={mergedTaskListAndShow} />
          </AccordionPanel>
        )}
        {!!mergedTaskListAndShow.runbook_component_id && (
          <TaskSourceRunbookPanel
            iconColor={selectedStreamColor}
            timezone={timezone}
            // @ts-ignore - all our types in this file are wrong - using taskListTask when should be taskShowTask
            sourceRunbookId={mergedTaskListAndShow.source_runbook_id}
            // @ts-ignore
            sourceRunbookName={mergedTaskListAndShow.runbook_component_name}
            sourceRunbookTaskInternalId={mergedTaskListAndShow.source_internal_id}
            // @ts-ignore
            sourceRunbookFieldValues={mergedTaskListAndShow.runbook_component_field_values}
          />
        )}
        <AccordionPanel
          iconColor={selectedStreamColor}
          icon="info"
          label={t('description.label')}
          suffix={
            <Tooltip content={t('description.tooltip')}>
              <Icon
                icon="open-new"
                color="text-light"
                data-testid="open-description-modal-icon"
                onClick={() => setDescriptionModalOpen(true)}
                onKeyDown={e => {
                  if (e.code === 'Space' || e.code === 'Enter') {
                    setDescriptionModalOpen(true)
                  }
                }}
                tabIndex={0}
                css="display: block"
              />
            </Tooltip>
          }
        >
          <Box gap="medium">
            <TextEditorField<TaskEditFormType>
              formRef={formRef}
              name="description"
              label={t('fields.description.label')}
              readOnly={!allowInProgressEdit}
              ref={descriptionFieldRef}
              plain
            />

            {settings_task_description_on_task_start && (
              <Message data-testid="show-description-on-task-start-info" type="info">
                {t('description.showOnTaskStartInfo')}
              </Message>
            )}
          </Box>
        </AccordionPanel>
        <TaskEditAssignedUsersAndTeamsPanel
          iconColor={selectedStreamColor}
          userIds={userIds ?? []}
          runbookTeamIds={runbookTeamIds ?? []}
          setValue={setValue}
          readOnly={!can?.update && !(isDynamic && task.stage === 'in-progress')}
        />
        <TaskEditDependenciesPanel
          successorIds={successorIds ?? []}
          predecessorIds={task.predecessor_ids ?? []}
          possiblePredecessorsIds={
            possiblePredecessorsIds && possiblePredecessorsIds.length > 0
              ? possiblePredecessorsIds
              : task.predecessor_ids
          }
          readOnly={readOnly}
          iconColor={selectedStreamColor}
          taskTypeLookup={taskTypeLookup}
          streamLookup={streamLookup}
        />
        <TaskAdvancedSettings iconColor={selectedStreamColor} readOnly={readOnly} />
        <TaskOtherDetails
          iconColor={selectedStreamColor}
          createdBy={taskAuthorName}
          createdAt={task.created_at}
          updatedAt={task.updated_at}
          timezone={timezone}
        />
        <TaskWebhooks iconColor={selectedStreamColor} taskInternalId={task.internal_id} runbookId={runbookId} />
      </Accordion>
    </>
  )
}

const TaskTypeSelectWrapper = styled(Box)<{ isTaskTypeCommsOff: boolean }>`
  flex-grow: 1;
  max-width: ${({ isTaskTypeCommsOff }) => (isTaskTypeCommsOff ? '100%' : '50%')};
`

const IntegrationIcon = styled.img`
  height: 22px;
  width: 22px;
`
const MessageStatusWrapper = styled(Box)`
  display: inline-block;
  position: relative;
  top: 5px;
  padding-bottom: 20px;
`
